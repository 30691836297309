import balaji from './component1/balaji.png'
import './App.css';
import Bills from './component1/bills';


function App() {
  return (
    <div className="App">
    <Bills/>
    </div>
  );
}

export default App;
